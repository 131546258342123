import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  ClaimType,
  FileInput,
  PhysicalQualityDeviationType,
  Role,
} from "../../../../api/graphql/graphql-global-types";
import { useUpdateClaimMutation } from "../../../../api/graphql/mutations/UpdateClaim";
import {
  ClaimAttachment,
  CLAIM_QUERY,
  useClaimQuery,
} from "../../../../api/graphql/queries/claim";
import { Abilities } from "../../../../domain/models/abilities";
import {
  IChemicalQualitySection,
  ILogisticQualitySection,
  IMaterialSection,
  IOrderSection,
  IPartnerServiceSection,
  IPhysicalQualitySection,
  IProductionSection,
} from "../types/claim-types";
import { stripTypenames } from "../../../../utils/apollo-helper";
import { useAddCommentToClaimMutation } from "../../../../api/graphql/mutations/AddCommentToClaim";
import {
  COMMENT_QUERY,
  useCommentsForClaimQuery,
} from "../../../../api/graphql/queries/commentsForClaim";
import { AuthContext } from "../../../../context/AuthContext";
import { useAssignRoleToClaim } from "../../../../api/graphql/mutations/AssignRoleToClaim";
import {
  claim_claim,
  claim_claim_createdBy,
} from "../../../../api/graphql/queries/types/claim";
import { useHistory } from "react-router-dom";
import { commentsForClaim_claim_comments } from "../../../../api/graphql/queries/types/commentsForClaim";
import { CLAIM_DEFAULTS } from "../../../../utils/claim-defaults";

interface IEditClaimContext {
  claimNumber: number;
  claimType: ClaimType | undefined;
  orderSection: IOrderSection;
  materialSection: IMaterialSection[];
  physicalQualitySection: IPhysicalQualitySection;
  chemicalQualitySection: IChemicalQualitySection;
  logisticQualitySection: ILogisticQualitySection;
  productionSection: IProductionSection;
  files: FileInput[];
  existingFiles: ClaimAttachment[];
  partnerServiceSection: IPartnerServiceSection;
  comments: commentsForClaim_claim_comments[];
  assignedRole: Role | undefined;
  disabled: boolean;
  submitLoading: boolean;
  commentsLoading: boolean;
  assigneeRole: Role;
  claim: claim_claim | null | undefined;
  createdBy: claim_claim_createdBy | null;
  onUpdateClaimType: (claimType: ClaimType | undefined) => void;
  onUpdateOrderSection: (orderSection: IOrderSection) => void;
  onUpdateMaterialSection: (materialSection: IMaterialSection[]) => void;
  onUpdatePhysicalQualitySection: (
    physicalQualitySection: IPhysicalQualitySection
  ) => void;
  onUpdateChemicalQualitySection: (
    chemicalQualitySection: IChemicalQualitySection
  ) => void;
  onUpdateLogisticQualitySection: (
    logisticQualitySection: ILogisticQualitySection
  ) => void;
  onUpdateProductionSection: (productionSection: IProductionSection) => void;
  onUpdateFiles: (files: FileInput[]) => void;
  onUpdatePartnerServiceSection: (
    partnerServiceSection: IPartnerServiceSection
  ) => void;
  hasAbility: (ability: string) => boolean;
  addComment: (comment: string) => void;
  onSubmitUpdateClaimAndAssign: (
    role: Role,
    comment: string,
    assigneeId: string | undefined,
    closeAssignDialog: () => void
  ) => void;
  onSubmitUpdateClaim: () => Promise<boolean>;
  isValid: () => boolean;
  isValidUser: () => boolean;
  setAssigneeRole: (role: Role) => void;
  getExcludedAssignRoles: () => Role[];
  getAssigneeRole: () => Role | undefined;
}

const DEFAULT_VALUES: IEditClaimContext = {
  claimNumber: NaN,
  ...CLAIM_DEFAULTS,
  existingFiles: [],
  assignedRole: Role.STOFFSTROM,
  comments: [],
  disabled: false,
  commentsLoading: false,
  submitLoading: false,
  assigneeRole: Role.STOFFSTROM,
  claim: null,
  createdBy: null,
  onUpdateClaimType: () => {},
  onUpdateOrderSection: () => {},
  onUpdateMaterialSection: () => {},
  onUpdatePhysicalQualitySection: () => {},
  onUpdateChemicalQualitySection: () => {},
  onUpdateLogisticQualitySection: () => {},
  onUpdateProductionSection: () => {},
  onUpdateFiles: () => {},
  onUpdatePartnerServiceSection: () => {},
  hasAbility: () => false,
  addComment: () => {},
  onSubmitUpdateClaimAndAssign: () => {},
  onSubmitUpdateClaim: async () => true,
  isValid: () => false,
  isValidUser: () => false,
  setAssigneeRole: () => {},
  getExcludedAssignRoles: () => [],
  getAssigneeRole: () => Role.STOFFSTROM,
};

interface IEditClaimProps {
  claimNumber: number;
  hasAbility: (ability: string) => boolean;
}

const useEditClaimProvider = ({
  claimNumber,
  hasAbility,
}: IEditClaimProps): IEditClaimContext => {
  const { t } = useTranslation();

  const { data: claimResult } = useClaimQuery({
    variables: { claimNumber },
    skip: isNaN(claimNumber),
  });

  const {
    data: commentsResult,
    loading: commentsLoading,
    refetch: refetchCommentsForClaim,
  } = useCommentsForClaimQuery({
    variables: { claimNumber },
    skip: isNaN(claimNumber),
  });

  const [claimType, setClaimType] = useState(DEFAULT_VALUES.claimType);
  const [orderSection, setOrderSection] = useState(DEFAULT_VALUES.orderSection);
  const [materialSection, setMaterialSection] = useState(
    DEFAULT_VALUES.materialSection
  );
  const [physicalQualitySection, setPhysicalQualitySection] = useState(
    DEFAULT_VALUES.physicalQualitySection
  );
  const [chemicalQualitySection, setChemicalQualitySection] = useState(
    DEFAULT_VALUES.chemicalQualitySection
  );
  const [logisticQualitySection, setLogisticQualitySection] = useState(
    DEFAULT_VALUES.logisticQualitySection
  );
  const [productionSection, setProductionSection] = useState(
    DEFAULT_VALUES.productionSection
  );
  const [files, setFiles] = useState(DEFAULT_VALUES.files);
  const [existingFiles, setExistingFiles] = useState(
    DEFAULT_VALUES.existingFiles
  );
  const [partnerServiceSection, setPartnerServiceSection] = useState(
    DEFAULT_VALUES.partnerServiceSection
  );
  const [comments, setComments] = useState(DEFAULT_VALUES.comments);
  const [assignedRole, setAssignedRole] = useState(DEFAULT_VALUES.assignedRole);
  const [assigneeRole, setAssigneeRole] = useState(DEFAULT_VALUES.assigneeRole);
  const [createdBy, setCreatedBy] = useState(DEFAULT_VALUES.createdBy);
  const [claim, setClaim] = useState(DEFAULT_VALUES.claim);
  const history = useHistory();

  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const existingClaim = commentsResult?.claim;
    if (existingClaim) {
      setComments(existingClaim.comments);
    }
  }, [commentsResult]);

  useEffect(() => {
    const existingClaim = claimResult?.claim;
    if (existingClaim) {
      setClaim(existingClaim);
      setClaimType(existingClaim.claimType ?? undefined);
      setOrderSection(existingClaim.orderSection);
      setMaterialSection(existingClaim.materialSection);
      setPhysicalQualitySection(existingClaim.physicalQualitySection);
      setChemicalQualitySection(existingClaim.chemicalQualitySection);
      setLogisticQualitySection(existingClaim.logisticQualitySection);
      setProductionSection(
        existingClaim.productionSection ?? DEFAULT_VALUES.productionSection
      );
      setFiles(DEFAULT_VALUES.files);
      setExistingFiles(existingClaim.attachments);
      setPartnerServiceSection(existingClaim.partnerServiceSection);
      setAssignedRole(existingClaim.assignedRole);
      setAssigneeRole(Role.STOFFSTROM);
      setCreatedBy(existingClaim.createdBy);
    }
  }, [claimResult]);

  const getAssigneeRole = (): Role => {
    if (assignedRole === Role.LABOR) {
      return Role.STOFFSTROM;
    }
    if (assignedRole === Role.STOFFSTROM) {
      if (
        claimType === ClaimType.UNPLANNED &&
        (productionSection == null ||
          !productionSection.plantId ||
          !productionSection.reason ||
          !productionSection.recommendedAction)
      ) {
        return Role.LABOR;
      }
      return Role.PARTNER_SERVICE;
    }
    return Role.STOFFSTROM;
  };

  const [updateClaimMutation, { loading, error }] = useUpdateClaimMutation({
    update: (_, mutationResult) => {
      if (mutationResult.data?.updateClaim) {
        toast.success(t("edit_claim.result.success"));
      }
    },
    rethrow: false,
  });

  const [assignRoleToClaimMutation, { loading: assignRoleToClaimLoading }] =
    useAssignRoleToClaim();

  const [addCommentToClaim, { loading: addCommentLoading }] =
    useAddCommentToClaimMutation({
      update: (_, mutationResult) => {
        if (mutationResult.data?.addCommentToClaim) {
          toast.success(t("edit_claim.add_comment.result.success"));
        }
      },
      rethrow: false,
    });

  const addComment = (comment: string): void => {
    if (comment) {
      addCommentToClaim({ variables: { claimNumber, comment } }).then(() => {
        refetchCommentsForClaim();
      });
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(t("edit_claim.result.error"));
    }
  }, [error]);

  const onSubmitUpdateClaimAndAssign = async (
    role: Role,
    comment: string,
    assigneeId: string | undefined,
    closeAssignDialog: () => void
  ) => {
    const isUpdated = await onSubmitUpdateClaim();
    if (!isUpdated) {
      closeAssignDialog();
      return;
    }

    await assignRoleToClaimMutation({
      variables: { claimNumber, role, assigneeId, comment },
      refetchQueries: [
        { query: CLAIM_QUERY, variables: { claimNumber } },
        { query: COMMENT_QUERY, variables: { claimNumber } },
      ],
    });

    setAssignedRole(undefined);
    if (hasAbility(Abilities.LABOR) && !hasAbility(Abilities.STOFFSTROM)) {
      history.push("/role/labor");
    } else {
      history.push("/claims");
    }
    closeAssignDialog();
  };

  const onSubmitUpdateClaim = async () => {
    if (!isValid()) {
      toast.error(t("edit_claim.form.validation_error"));
      return false;
    }
    if (!claimType) {
      return false;
    }

    const claimInput = stripTypenames({
      claimType,
      materialSection,
      chemicalQualitySection,
      logisticQualitySection,
      physicalQualitySection,
      productionSection,
      files,
      partnerServiceSection,
      orderId: "",
    });

    await updateClaimMutation({
      variables: {
        claimNumber,
        claim: claimInput,
      },
    });
    return true;
  };

  const isValidUser = (): boolean => {
    const assignedUserId = claimResult?.claim?.assignedUser?.id;
    return assignedUserId === userId;
  };

  const isValid = (): boolean => {
    if (!claimType) {
      return false;
    }
    if (claimType === ClaimType.UNPLANNED && !isValidProductionSection()) {
      return false;
    }
    if (!isValidPhysicalQualitySection() || !isValidChemicalQualitySection()) {
      return false;
    }
    return true;
  };

  const isValidPhysicalQualitySection = (): boolean => {
    if (
      physicalQualitySection.deviationType !==
        PhysicalQualityDeviationType.STAUBEND &&
      physicalQualitySection.deviationType != null &&
      !physicalQualitySection.description
    ) {
      return false;
    }
    return true;
  };

  const isValidChemicalQualitySection = (): boolean => {
    if (
      chemicalQualitySection.deviationType != null &&
      !chemicalQualitySection.description
    ) {
      return false;
    }
    return true;
  };

  const isValidProductionSection = (): boolean => {
    if (hasAbility(Abilities.STOFFSTROM)) return true;
    if (productionSection == null) return true;
    if (productionSection.plantId == null) return false;
    if (!productionSection.reason) return false;
    if (!productionSection.recommendedAction) return false;
    return true;
  };

  const getExcludedAssignRoles = (): Role[] => {
    if (assignedRole === Role.LABOR) {
      return [Role.PARTNER_SERVICE, Role.LABOR, Role.VERTRIEB];
    }
    if (assignedRole === Role.STOFFSTROM) {
      return [Role.VERTRIEB, Role.STOFFSTROM];
    }
    return [Role.VERTRIEB, Role.LABOR, Role.PARTNER_SERVICE];
  };

  return {
    claimNumber,
    claimType,
    orderSection,
    materialSection,
    physicalQualitySection,
    chemicalQualitySection,
    logisticQualitySection,
    productionSection,
    files,
    existingFiles,
    partnerServiceSection,
    comments,
    assignedRole,
    disabled: loading || assignRoleToClaimLoading,
    commentsLoading: addCommentLoading || commentsLoading,
    submitLoading: loading || assignRoleToClaimLoading,
    assigneeRole,
    claim,
    createdBy,
    onUpdateClaimType: setClaimType,
    onUpdateOrderSection: setOrderSection,
    onUpdatePhysicalQualitySection: setPhysicalQualitySection,
    onUpdateMaterialSection: setMaterialSection,
    onUpdateChemicalQualitySection: setChemicalQualitySection,
    onUpdateLogisticQualitySection: setLogisticQualitySection,
    onUpdateProductionSection: setProductionSection,
    onUpdateFiles: setFiles,
    onUpdatePartnerServiceSection: setPartnerServiceSection,
    hasAbility,
    addComment,
    onSubmitUpdateClaimAndAssign,
    onSubmitUpdateClaim,
    isValid,
    isValidUser,
    setAssigneeRole,
    getExcludedAssignRoles,
    getAssigneeRole,
  };
};

const EditClaimContext = React.createContext<IEditClaimContext>(DEFAULT_VALUES);

export const EditClaimProvider: FC<IEditClaimProps> = (props) => {
  const editClaim = useEditClaimProvider(props);

  return (
    <EditClaimContext.Provider value={editClaim}>
      {props.children}
    </EditClaimContext.Provider>
  );
};

export const EditClaimConsumer = EditClaimContext.Consumer;

export const useEditClaimContext = () => React.useContext(EditClaimContext);
